import { DescribableEnumeration } from '@bp/shared/models/core';

export class PaymentOptionBaseType extends DescribableEnumeration {

	readonly code: number | null;

	constructor(config?: {
		displayName?: string;
		description?: string;
		code?: number | null;
	}) {
		super(config?.displayName, config?.description);

		this.code = config?.code ?? null;
	}

}
