import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutStatus extends Enumeration {

	static notActive = new CheckoutStatus();

	static active = new CheckoutStatus();

	static suspended = new CheckoutStatus();

	get isActive(): boolean {
		return this === CheckoutStatus.active;
	}

}
