/* eslint-disable @typescript-eslint/naming-convention */

import { CryptoCurrencyCode, FiatCurrencyCode } from '@bp/shared/models/currencies';
import { CountryCode } from '@bp/shared/models/countries';
import { Dictionary } from '@bp/shared/typings';
import { PaymentOptionTypeSnakeCaseLiterals } from '@bp/shared/models/business';

export interface IBrowserDetails {
	java_enabled: string;
	java_script_enabled: string;
	language: string;
	color_depth: number;
	screen_height: number;
	screen_width: number;
	time_zone: string;
	user_agent: string;
}

export interface IRequestUserData {
	first_name?: string;
	last_name?: string;
	city?: string;
	zip_code?: string;
	state?: string;
	country?: CountryCode;
	phone?: string;
	phone_country_dial_code?: string;
	email?: string;
	additional_data?: IBrowserDetails;
	browser_details?: IBrowserDetails;
}

export interface IRequestServiceData {
	tracking_id?: string;
	affiliate_id?: string;
	ip?: string;
	amount_lock?: boolean;
	currency_lock?: boolean;
}

export interface IRequestDeposit {
	type: PaymentOptionTypeSnakeCaseLiterals;
	amount?: number;
	currency?: FiatCurrencyCode;
	provider?: string;

	/**
	 * Psps fingerprints
	 */
	ddc?: Dictionary<string>; // the key is the psp name
	payment_id?: string;
	payment_method_instance_id?: string;
	additional_data?: IBrowserDetails;
	browser_details?: IBrowserDetails;
}

export interface IRequestMpiDeposit {
	type: PaymentOptionTypeSnakeCaseLiterals;
	provider?: string;
}

export interface IDeviceInfo {
	device_no: string;
	device_uid: string;
}

export interface IRequestCardDeposit
	extends IRequestUserData, IRequestServiceData, IRequestDeposit, Partial<IDeviceInfo> {
	address1?: string;
	card_holder_name: string;
	credit_card_number: string;
	expire_month: number;
	expire_year: number;
	cvv2: string;
	payment_method_type: PaymentOptionTypeSnakeCaseLiterals;
	save_card: boolean;
	credit_card_token?: string;
	birth_date?: number;
	gender?: 'female' | 'male';
	personal_id?: string;
	personal_id_type?: string;
	pin?: string;
	installment_option_id?: string;
	three_ds_provider_auth_result_ready?: boolean;
	[ key: string ]: unknown;
}

export interface IRequestApmDeposit
	extends IRequestUserData, IRequestServiceData, IRequestDeposit {
	address?: string;
	address_street?: string;
	address_number?: string;
	address_district?: string;
	address_complement?: string;
	country_code?: string;
	bank_code?: string;
	bank_payment_type?: string;
	open_in_new_window?: boolean;
	subtype: string;
	birth_date?: number;
	gender?: 'female' | 'male';
	personal_id?: string;
	personal_id_type?: string;
	bank_account_name?: string;
	bank_agency?: string;
	iban?: string;
	account_number?: string;
	otp_code?: string;
	sort_code?: string;
	expire_month?: number;
	expire_year?: number;
	[ key: string ]: unknown;
}

export interface IRequestCryptoDeposit
	extends IRequestUserData, IRequestServiceData, IRequestDeposit {
	address: string;
	crypto_currency: CryptoCurrencyCode;
	order_id: string;

	/**
	 * This parameter helps to identify the right recipient. More info https://wirexapp.com/help/article/what-is-a-stellar-memo-0050.
	 * Done for a few cryptos
	 */
	memo?: string;
}

export type RequestDeposit = IRequestApmDeposit | IRequestCardDeposit;
