import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

import { TransactionStatus } from './transaction-status';

export type TransactionKindName = GetEnumerationLiterals<typeof TransactionKind>;

export class TransactionKind extends Enumeration {

	static readonly payment = new TransactionKind();

	static readonly threeDs = new TransactionKind('Bridgerpay 3DS');

	static readonly payout = new TransactionKind();

	static readonly refund = new TransactionKind();

	get isThreeDs(): boolean {
		return this === TransactionKind.threeDs;
	}

	get isPayment(): boolean {
		return this === TransactionKind.payment;
	}

	getStatuses() {
		switch (this) {
			case TransactionKind.payment:
				return TransactionStatus.paymentRelated;

			case TransactionKind.threeDs:
				return TransactionStatus.threeDsRelated;

			case TransactionKind.payout:
				return TransactionStatus.payoutRelated;

			case TransactionKind.refund:
				return TransactionStatus.refundRelated;

			default:
				throw new Error(`Unknown transaction kind ${ this }`);
		}
	}
}
