import { GetEnumerationLiterals } from '@bp/shared/models/core/enum';
import { CamelToSnakeCaseLiteral } from '@bp/shared/typings';

import { PaymentOptionBaseType } from './payment-option-base-type';

export type PspPaymentOptionTypeLiterals = GetEnumerationLiterals<typeof PspPaymentOptionType>;

export type PspPaymentOptionTypeSnakeCaseLiterals = CamelToSnakeCaseLiteral<PspPaymentOptionTypeLiterals>;

export class PspPaymentOptionType extends PaymentOptionBaseType {

	static creditCard = new PspPaymentOptionType({ displayName: 'Credit card', code: 0 });

	static apm = new PspPaymentOptionType({ displayName: 'APM', code: 4 });

	static walletApplePay = new PspPaymentOptionType({ displayName: 'Apple Pay', code: 10 });

	static walletApplePayDirect = new PspPaymentOptionType({ displayName: 'Apple Pay Direct', code: 14 });

	static walletGooglePay = new PspPaymentOptionType({ displayName: 'Google Pay', code: 11 });

	static external = new PspPaymentOptionType({ code: 9 });

	static wallet = new PspPaymentOptionType({ displayName: 'Digital Wallet', code: 13 });

	static pos = new PspPaymentOptionType({ displayName: 'POS', code: 12 });

	static crypto = new PspPaymentOptionType({ code: 2 });

	// #region Used in checkout as computed options

	static paywith = new PspPaymentOptionType();

	static banks = new PspPaymentOptionType();

	// #endregion

	// #region deprecated

	static voucher = new PspPaymentOptionType({ code: 3 });

	// #endregion

	static assignable = [
		PspPaymentOptionType.creditCard,
		PspPaymentOptionType.apm,
		PspPaymentOptionType.walletApplePay,
		PspPaymentOptionType.walletApplePayDirect,
		PspPaymentOptionType.walletGooglePay,
		PspPaymentOptionType.wallet,
		PspPaymentOptionType.external,
		PspPaymentOptionType.crypto,
		PspPaymentOptionType.pos,
	];

	static isPspBased(type?: PspPaymentOptionType | null): boolean {
		return this.assignable.includes(type!);
	}

	static isDeprecated(type?: PspPaymentOptionType | string | null): boolean {
		return !!PspPaymentOptionType.parse(type)?.isDeprecated;
	}

	get isBanks(): boolean {
		return this === PspPaymentOptionType.banks;
	}

	get isPaywith(): boolean {
		return this === PspPaymentOptionType.paywith;
	}

	get isCreditCard(): boolean {
		return this === PspPaymentOptionType.creditCard;
	}

	get isApm(): boolean {
		return this === PspPaymentOptionType.apm;
	}

	get isWalletApplePay(): boolean {
		return this === PspPaymentOptionType.walletApplePay;
	}

	get isWalletApplePayDirect(): boolean {
		return this === PspPaymentOptionType.walletApplePayDirect;
	}

	get isWalletGooglePay(): boolean {
		return this === PspPaymentOptionType.walletGooglePay;
	}

	get isWallet(): boolean {
		return this === PspPaymentOptionType.wallet;
	}

	get isWalletKind(): boolean {
		return this.isWalletApplePay || this.isWalletGooglePay || this.isWallet || this.isWalletApplePayDirect;
	}

	get isExternal(): boolean {
		return this === PspPaymentOptionType.external;
	}

	get isPos(): boolean {
		return this === PspPaymentOptionType.pos;
	}

	get isCrypto(): boolean {
		return this === PspPaymentOptionType.crypto;
	}

	get isSortable(): boolean {
		return this.isCreditCard || this.isWalletGooglePay || this.isWalletApplePay || this.isWalletApplePayDirect;
	}

	get isDeprecated(): boolean {
		return [ PspPaymentOptionType.voucher ].includes(this);
	}

}
