import { isNumber } from 'lodash-es';

import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';
import { bpQueueMicrotask } from '@bp/shared/utilities/core';

export type TransactionStatusName = GetEnumerationLiterals<typeof TransactionStatus>;

export class TransactionStatus extends Enumeration {

	static readonly init = new TransactionStatus('Init', 5);

	static readonly approved = new TransactionStatus('Approved', 1);

	static readonly approvedOnHold = new TransactionStatus('Approved On Hold', 4);

	static readonly authorized = new TransactionStatus('Authorized', 9);

	static readonly inProcess = new TransactionStatus('In Process', 2);

	static readonly pending = new TransactionStatus('Pending', 3);

	static readonly captureInProgress = new TransactionStatus('Capture In Progress', 12);

	static readonly refunded = new TransactionStatus('Refunded', 6);

	static readonly partlyRefunded = new TransactionStatus('Partly Refunded', 7);

	static readonly voided = new TransactionStatus('Voided', 10);

	static readonly declined = new TransactionStatus('Declined', 0);

	static readonly declinedDueTo3DAuthFailure = new TransactionStatus('Declined Due To 3D Auth Failure', -4);

	static readonly declinedDoNotTryAgain = new TransactionStatus('Declined Do Not Try Again', -3);

	static readonly declinedTryAgain = new TransactionStatus('Declined Try Again', -5);

	static readonly declinedByTimeout = new TransactionStatus('Declined By Timeout', -2);

	static readonly declinedDueToInvalidCreditCard = new TransactionStatus('Declined Due To Invalid Credit Card', -1);

	static readonly declinedDueToInvalidData = new TransactionStatus('Declined Due To Invalid Data', null);

	// Means some general unhandled processing error
	static readonly declinedDueToError = new TransactionStatus('Declined Due To Error', -6);

	static readonly filteredByRule = new TransactionStatus('Filtered By Rule', 11);

	static readonly skippedDueToMissingFingerprint = new TransactionStatus('Skipped Due To Missing Fingerprint', 13);

	static readonly threeDsInit = new TransactionStatus('3DS Initialization', -10);

	static readonly threeDsInitFailed = new TransactionStatus('3DS Initialization failed', -11);

	static readonly threeDsAuthenticated = new TransactionStatus('3DS Authenticated', -9);

	static readonly threeDsNotAuthenticated = new TransactionStatus('3DS Not authenticated', -8);

	static readonly threeDsFailed = new TransactionStatus('3DS Authentication failed', -7);

	static paymentRelated: TransactionStatus[];

	static refundRelated: TransactionStatus[];

	static payoutRelated: TransactionStatus[];

	static threeDsRelated: TransactionStatus[];

	static declinedLike: TransactionStatus[];

	static override parseHook = (value: unknown): TransactionStatus | null => isNumber(value)
		? TransactionStatus.getList().find(status => status.code === value) ?? null
		: null;

	get isInProcess(): boolean {
		return this === TransactionStatus.inProcess;
	}

	get isApproved(): boolean {
		return this === TransactionStatus.approved;
	}

	get isAuthorized(): boolean {
		return this === TransactionStatus.authorized;
	}

	get isApprovedOnHold(): boolean {
		return this === TransactionStatus.approvedOnHold;
	}

	get isPartlyRefunded(): boolean {
		return this === TransactionStatus.partlyRefunded;
	}

	static {
		this.paymentRelated = [
			this.init,
			this.approved,
			this.approvedOnHold,
			this.authorized,
			this.inProcess,
			this.pending,
			this.captureInProgress,
			this.refunded,
			this.partlyRefunded,
			this.voided,
			this.declined,
			this.declinedDueTo3DAuthFailure,
			this.declinedDueToInvalidData,
			this.declinedByTimeout,
			this.declinedDueToError,
			this.declinedDueToInvalidCreditCard,
			this.filteredByRule,
			this.skippedDueToMissingFingerprint,
		];

		this.payoutRelated = [
			this.init,
			this.declinedByTimeout,
			this.approved,
			this.declined,
			this.inProcess,
			this.pending,
		];

		this.refundRelated = [
			this.approved,
			this.approvedOnHold,
			this.inProcess,
			this.pending,
			this.declined,
			this.declinedByTimeout,
			this.declinedDueToError,
			this.declinedDueToInvalidCreditCard,
		];

		this.threeDsRelated = [
			this.threeDsInit,
			this.threeDsInitFailed,
			this.threeDsAuthenticated,
			this.threeDsNotAuthenticated,
			this.threeDsFailed,
		];

		this.declinedLike = [
			TransactionStatus.declined,
			TransactionStatus.declinedByTimeout,
			TransactionStatus.declinedDoNotTryAgain,
			TransactionStatus.declinedDueTo3DAuthFailure,
			TransactionStatus.declinedDueToError,
			TransactionStatus.declinedDueToInvalidCreditCard,
			TransactionStatus.declinedDueToInvalidData,
			TransactionStatus.threeDsInitFailed,
			TransactionStatus.threeDsFailed,
			TransactionStatus.threeDsNotAuthenticated,
		];
	}

	isApprovedLike!: boolean;

	isPendingLike!: boolean;

	isDeclinedLike!: boolean;

	canBeRechecked!: boolean;

	isVoided!: boolean;

	isCreditCardInProcess!: boolean;

	isDeclinedDueToInvalidCard!: boolean;

	isThreeDsRelated!: boolean;

	constructor(
		displayName?: string,
		public readonly code?: number | null,
	) {
		super(displayName);

		bpQueueMicrotask((): void => {
			this.isApprovedLike = this._checkIsApprovedLike();

			this.isPendingLike = this.__checkIsPendingLike();

			this.isDeclinedLike = TransactionStatus.declinedLike.includes(this);

			this.canBeRechecked = this.__checkCanBeRechecked();

			this.isVoided = this === TransactionStatus.voided;

			this.isCreditCardInProcess = this === TransactionStatus.inProcess;

			this.isDeclinedDueToInvalidCard = this === TransactionStatus.declinedDueToInvalidCreditCard;

			this.isThreeDsRelated = this.name.startsWith('threeDs');
		});
	}

	private _checkIsApprovedLike(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.approved,
			TransactionStatus.approvedOnHold,
			TransactionStatus.authorized,
		])
			.includes(this);
	}

	private __checkCanBeRechecked(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.pending,
			TransactionStatus.inProcess,
			TransactionStatus.captureInProgress,
		])
			.includes(this);
	}

	private __checkIsPendingLike(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.init,
			TransactionStatus.pending,
			TransactionStatus.inProcess,
			TransactionStatus.captureInProgress,
			TransactionStatus.threeDsInit,
			TransactionStatus.threeDsAuthenticated,
		])
			.includes(this);
	}

}
