import { FE_MOCK_REGULAR_CHECKOUT_SESSION_LITERAL } from '@bp/shared/domains/checkouts';

export const MOCK_SESSION_KEYS = {
	// custom themes
	highlow: 'highlow',
	highlowV2: 'highlow-v2',
	highlowQoneco: 'highlow-qoneco',
	highlowXoro: 'highlow-xoro',
	davidShields: 'david-shields',
	jamesAlenBluenile: 'james-alen-bluenile',
	jamesAlen: 'james-alen',
	securePharma: 'secure-pharma',
	passportCard: 'passport-card',

	// sessions of different behaviors
	regularSession: FE_MOCK_REGULAR_CHECKOUT_SESSION_LITERAL,
	regularClosedSession: 'regular-closed-session',
	justPaywith: 'just-paywith',
	completePaywith: 'complete-paywith',
	incompletePaywith: 'incomplete-paywith',
	pendingPaywith: 'pending-paywith',

	regularSessionWithPaywithPaymentOption: 'regular-session-with-paywith-payment-option',
	regularSessionWithOrderSummary: 'regular-session-with-order-summary',
	motoSession: 'moto-session',
	paymentCardTokenSession: 'payment-card-token-session',
	ddcSession: 'ddc-session',
	googlePay: 'google-pay',
};

export const MOCK_SESSION_IDS = {
	hostedBlox: 'db3b1083-eb0a-48a8-9a79-31e6f2825637',
};

export function isMockSession(sessionCheckoutKeyOrSessionId: string): boolean {
	return Object.values(MOCK_SESSION_KEYS).includes(sessionCheckoutKeyOrSessionId) || Object.values(MOCK_SESSION_IDS).includes(sessionCheckoutKeyOrSessionId);
}
