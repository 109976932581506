/* eslint-disable @typescript-eslint/naming-convention */

import { FiatCurrency } from '@bp/shared/models/currencies';

import { ILogo } from './checkout-session';

export interface IBank {

	bank_code: string;

	name: string;

	currency: FiatCurrency;

	image_url?: string;

	logo: ILogo;

	payment_types?: string[];

}
