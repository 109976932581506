import { isNumber } from 'lodash-es';

import { GetEnumerationLiterals } from '@bp/shared/models/core/enum';
import { CamelToSnakeCaseLiteral } from '@bp/shared/typings';

import { PspPaymentOptionType } from './psp-payment-option-type';

export type PaymentOptionTypeLiterals = GetEnumerationLiterals<typeof PaymentOptionType>;

export type PaymentOptionTypeSnakeCaseLiterals = Exclude<CamelToSnakeCaseLiteral<PaymentOptionTypeLiterals>, 'assignable' | 'static_option' | 'static_options'>;

export class PaymentOptionType extends PspPaymentOptionType {

	static wireTransfer = new PaymentOptionType({
		displayName: 'Bank wire details',
		description: 'A set of bank account requisites per currency',
		code: 1,
	});

	static externalLink = new PaymentOptionType({
		description: 'A custom link to any internet resource',
		code: 7,
	});

	static cryptoWallet = new PaymentOptionType({
		displayName: 'Crypto wallets',
		description: 'A set of crypto currency addresses',
		code: 6,
	});

	static staticOptions = [
		PaymentOptionType.externalLink,
		PaymentOptionType.wireTransfer,
		PaymentOptionType.cryptoWallet,
	];

	static override assignable = [
		PaymentOptionType.creditCard,
		PaymentOptionType.apm,
		PaymentOptionType.walletApplePay,
		PaymentOptionType.walletApplePayDirect,
		PaymentOptionType.walletGooglePay,
		PaymentOptionType.wallet,
		PaymentOptionType.external,
		PaymentOptionType.pos,
		PaymentOptionType.crypto,
		...PaymentOptionType.staticOptions,
	];

	static staticOption = new PaymentOptionType({ displayName: 'Static options' });

	static isStatic(type: PaymentOptionType): boolean {
		return PaymentOptionType.staticOptions.includes(type);
	}

	static override parseHook = (value: unknown): PaymentOptionType | null => isNumber(value)
		? PaymentOptionType.getList().find(paymentOptionType => paymentOptionType.code === value) ?? null
		: null;

}
