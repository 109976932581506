import { snakeCase } from 'lodash-es';

import { Enumeration } from '@bp/shared/models/core/enum';

export class ConfigurablePsp extends Enumeration {

	static shiftFour = new ConfigurablePsp();

	get pspName(): string {
		return snakeCase(this.name);
	}

	override toJSON(): string {
		return this.pspName;
	}

}
